import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { Button, GlobalStyle, Stack, Text } from '@procsea/design-system'

import useDocumentTitle from 'src/hooks/useDocumentTitle'
import mail from 'src/images/mail.svg'
import { LocaleParams } from 'src/types'

import { AuthContainer, AuthMainCard, AuthMainCardImage } from '../common'
import AuthHeader from '../common/AuthHeader'

const ForgotPasswordDone = () => {
  useDocumentTitle(gettext('Reset Password'))

  const { locale } = useParams<LocaleParams>()

  return (
    <AuthContainer>
      <GlobalStyle styledTheme="light" />

      <AuthHeader data-e2e="forgot-password-done-header-link" />

      <AuthMainCard shadow="middle" spacing="xlarge">
        <Stack spacing="large">
          <AuthMainCardImage src={mail} />

          <Text size="xxxlarge" variant="secondary">
            {gettext('Reset password')}
          </Text>

          <Text data-e2e="reset-password-message" variant="black">
            {gettext('You are going to receive an email with instructions to reset your password')}
          </Text>

          <Link to={`/${locale}/accounts/login`}>
            <Button fluid data-e2e="forgot-password-done-login-link" variant="primary">
              {gettext('Back to login page')}
            </Button>
          </Link>
        </Stack>
      </AuthMainCard>
    </AuthContainer>
  )
}

export default ForgotPasswordDone
